
.ui.label.stats-popup {
  position: relative;

  .ui.popup {
    display: none;
    position: absolute;
    top: -200%;
    width: max-content;

    &:before {
      top: 95%;
    }

  }

  &.stats-views {
    .ui.popup {
      left: 0;

      &:before {
        top: 87%;
        left: 24%;
      }
    }
  }

  &.stats-downloads {
    .ui.popup {
      right: 0;

      &:before {
        top: 87%;
        right: 16%;
      }
    }
  }

  &:hover {
    .ui.popup {
      display: block !important;
    }
  }
}

[data-tooltip]::after {
  width: max-content;
  max-width: 80vw;
  white-space: break-spaces;
}
