/***********************************************
         Invenio Theme Header Overrides
***********************************************/

.ui.login.header {
    margin: 40px 0;
}
.ui.login.segment {
    padding: 15px 40px 40px 40px;
}
.ui.header:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6)  {
    font-size: 1em;
}

/*--- Negative ---*/
.ui.negative.header {
  color: @negativeHeaderColor !important;
}
a.ui.negative.header:hover {
  color: @negativeHover !important;
}
.ui.negative.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @negativeHeaderColor;
}

/*--- Positive ---*/

.ui.positive.header {
  color: @positiveHeaderColor !important;
}
a.ui.positive.header:hover {
  color: @positiveHover !important;
}
.ui.positive.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @positiveHeaderColor;
}

/*--- Warning ---*/

.ui.warning.header {
  color: @warningHeaderColor !important;
}
a.ui.warning.header:hover {
  color: @warningHover !important;
}
.ui.warning.dividing.header {
  border-bottom: @dividedColoredBorderWidth solid @warningHeaderColor;
}
