/***********************************************
         Invenio Theme Container Overrides
***********************************************/

.ui.container.cover-page {
  background-color: @brandColor;
  height: 20em;
  display: flex;
  align-items: center;

  & > .ui.container {
    vertical-align: middle;
  }

  .ui.header {
    color: @invertedTextColor;
  }
}

.ui.breadcrumbs.container {
  padding-top: 14px;
  padding-bottom: 14px;
}


.invenio-administration {

  .ui.container {

    &.error-handler {
      height: 60vh;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    &.dashboard {
      .dashboard-header{
        display: flex !important;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        text-align: end;
      }
    }
  }
}
