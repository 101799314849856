/***********************************************
         Invenio App RDM Header Overrides
***********************************************/

#rdm-footer-element {
  .ui.header {
    color: @footerTextLightColor;
  }
}


.main-record-content {
  h1,
  h1.ui.header,
  .ui.huge.header {
    font-size: 2rem;
  }

  h2,
  h2.ui.header,
  .ui.large.header {
    font-size: 1.5rem;

    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }

  h3,
  h3.ui.header,
  .ui.medium.header {
    font-size: 1.2rem;
  }

  h4,
  h4.ui.header,
  .ui.small.header {
    font-size: 1rem;
  }
}

aside.sidebar {
  h3.hidden {
    display: none;
  }
}

.ui.header  {
  word-break: break-word;

  &.text-muted {
    color: @mutedTextColor;
  }

  .doi.sub.header {
    display: inline-block;
  }

  &.highlight{
    background-color:  @highlightBackgroundColor;
  }
}
