/***********************************************
         Invenio Theme Message Overrides
***********************************************/

.ui.flashed.message {
    border-radius: 0;
    padding: 1px 0;
    z-index: 10;

    .ui.button.close-btn {
      background-color: transparent;
    }
}

.flashed.close.icon {
    cursor: pointer;
    margin: 0;
    opacity: .7;
    transition: opacity .1s ease;
}

.ui.success.flashed.message > .ui.grid.container {
  padding-right: 0.5em;
}

.ui.message.code {
  overflow: auto !important;
}

.ui.message {

  &.scroll-overflow {
    overflow-x: scroll;
  }

  &.no-border-radius {
    border-radius: 0 !important;
  }

  &.icon {
    .small.icon {
      font-size: @small !important;
    }
    .large.icon {
      font-size: @large !important;
    }
  }
}
