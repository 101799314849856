/***********************************************
         Invenio App RDM Icon Overrides
***********************************************/

.inline-id-icon {
  height: 1rem;
  min-height: 16px;
  width: auto;
  vertical-align: text-bottom;
}

.ui.label > .icon {
  //Overwrite default icon
  margin: 0 0.25rem 0 0;
}

.icon {
  &.error {
    border-color: @errorTextColor ;
    color: @errorTextColor;
  }

  &.carousel-arrow {
    text-shadow: 0 1px 2px rgba(0,0,0,.4);
    cursor: pointer;
    width: auto;
    position: relative;
    z-index: 1;
  }
}
