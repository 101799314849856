.ui.list {
  &.filter-list {
    .item {
      text-transform: capitalize;
      margin-right: 0.5 * @defaultMargin;
      margin-left: 0;
    }
  }
}

dl.features-list {
  dd {
    color: @invertedTextColorLight;
  }
}

footer  {
  ul.ui.link.list {
    margin: 0;

    li.item {
      padding-left: 0 !important;

      &:before {
        content:"";
      }
    }
  }
}