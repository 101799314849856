/***********************************************
         Invenio Theme Input Overrides
***********************************************/

/* it is hardcoded because the searchbar is wrapped in <form>
    and it can't be wrapped in invenio-search-ui
*/
#header-search-bar {
  .ui.input {
    width: 200px !important;
    height: 38px !important;

    &>input {
      width: 200px !important;
      height: 38px !important;
    }

  }
}

.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important;
}
